/** @jsx jsx */
import { jsx, Text, Input, Button, Flex, Box } from "theme-ui";
import styled from "@emotion/styled";
import LocalizedLink from "./LocalizedLink";

const Header = ({ invert = false }: { invert?: boolean }) => (
	<Flex
		sx={{
			pointerEvents: "none",
			zIndex: 10,
			position: "relative",
			maxWidth: [1500],
			mx: "auto",
			p: [2, 3, 4, 5],
			pb: [0, 0, 0, 0],
			justifyContent: "center",
			alignItems: "center",
			"& > a": {
				textDecoration: "none",
				color: "black"
			}
		}}>
		<LocalizedLink to='/' aria-label='Back to Home'>
			<Text sx={{ fontSize: [2, 3] }}>supertime</Text>
		</LocalizedLink>
	</Flex>
);

export default Header;

import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Facebook from "./Facebook";
import Twitter from "./Twitter";
import i18n from "../../../config/i18n";

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, banner, pathname, article, node, locale }) => {
	const { site } = useStaticQuery(query);
	const {
		defaultTitle,
		defaultDescription,
		siteLanguage,
		headline,
		categories,
	} = i18n[locale];

	const {
		buildTime,
		siteMetadata: {
			siteUrl,
			defaultBanner,
			ogLanguage,
			author,
			twitter,
			facebook,
		},
	} = site;

	const localizedPath = i18n[locale].default ? "" : `/${i18n[locale].path}`;
	const homeURL = `${siteUrl}${localizedPath}`;

	const seo = {
		title: title || defaultTitle,
		description: desc || defaultDescription,
		image: `${siteUrl}${banner || defaultBanner}`,
		url: `${siteUrl}${pathname || ""}`,
	};

	// schema.org in JSONLD format
	// https://developers.google.com/search/docs/guides/intro-structured-data
	// You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

	const schemaOrgWebPage = {
		"@context": "http://schema.org",
		"@type": "WebPage",
		url: homeURL,
		headline,
		inLanguage: siteLanguage,
		mainEntityOfPage: homeURL,
		description: defaultDescription,
		name: defaultTitle,
		author: {
			"@type": "Person",
			name: author,
		},
		copyrightHolder: {
			"@type": "Person",
			name: author,
		},
		copyrightYear: "2019",
		creator: {
			"@type": "Person",
			name: author,
		},
		publisher: {
			"@type": "Person",
			name: author,
		},
		datePublished: "2019-01-18T10:30:00+01:00",
		dateModified: buildTime,
		image: {
			"@type": "ImageObject",
			url: `${siteUrl}${defaultBanner}`,
		},
	};

	// Initial breadcrumb list

	const itemListElement = [
		{
			"@type": "ListItem",
			item: {
				"@id": siteUrl,
				name: "Homepage",
			},
			position: 1,
		},
		{
			"@type": "ListItem",
			item: {
				"@id": `${homeURL}/categories`,
				name: categories,
			},
			position: 2,
		},
	];

	let schemaArticle = null;

	if (article) {
		schemaArticle = {
			"@context": "http://schema.org",
			"@type": "Article",
			author: {
				"@type": "Person",
				name: author,
			},
			copyrightHolder: {
				"@type": "Person",
				name: author,
			},
			copyrightYear: "2019",
			creator: {
				"@type": "Person",
				name: author,
			},
			publisher: {
				"@type": "Organization",
				name: author,
				logo: {
					"@type": "ImageObject",
					url: `${siteUrl}${defaultBanner}`,
				},
			},
			datePublished: node.first_publication_date,
			dateModified: node.last_publication_date,
			description: seo.description,
			headline: seo.title,
			inLanguage: "en",
			url: seo.url,
			name: seo.title,
			image: {
				"@type": "ImageObject",
				url: seo.image,
			},
			mainEntityOfPage: seo.url,
		};
		// Push current blogpost into breadcrumb list
		itemListElement.push({
			"@type": "ListItem",
			item: {
				"@id": seo.url,
				name: seo.title,
			},
			position: 3,
		});
	}

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		description: "Breadcrumbs list",
		name: "Breadcrumbs",
		itemListElement,
	};

	return (
		<>
			<Helmet title={seo.title}>
				<html lang={siteLanguage} />
				<meta name='description' content={seo.description} />
				<meta
					name='p:domain_verify'
					content='6e33b5d6d97bec677e187f896657b7fe'
				/>
				<meta name='image' content={seo.image} />
				<meta name='gatsby-starter' content='Gatsby Starter Prismic i18n' />
				{/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
				{!article && (
					<script type='application/ld+json'>
						{JSON.stringify(schemaOrgWebPage)}
					</script>
				)}
				{article && (
					<script type='application/ld+json'>
						{JSON.stringify(schemaArticle)}
					</script>
				)}
				<script type='application/ld+json'>{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Facebook
				desc={seo.description}
				image={seo.image}
				title={seo.title}
				type={article ? "article" : "website"}
				url={seo.url}
				locale={ogLanguage}
				name={facebook}
			/>
			<Twitter
				title={seo.title}
				image={seo.image}
				desc={seo.description}
				username={twitter}
			/>
		</>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	banner: PropTypes.string,
	pathname: PropTypes.string,
	article: PropTypes.bool,
	node: PropTypes.object,
	locale: PropTypes.string,
};

SEO.defaultProps = {
	title: null,
	desc: null,
	banner: null,
	pathname: null,
	article: false,
	node: null,
	locale: "de-de",
};

const query = graphql`
	query SEO {
		site {
			buildTime(formatString: "YYYY-MM-DD")
			siteMetadata {
				siteUrl
				defaultBanner: banner
				ogLanguage
				author
				twitter
				facebook
			}
		}
	}
`;

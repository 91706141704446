import * as React from 'react'
import styled from '@emotion/styled'
import { Box } from 'theme-ui'
import './animate.css'

type ANIMATE_CSS =
  | 'bounce'
  | 'flash'
  | 'pulse'
  | 'rubberBand'
  | 'shake'
  | 'headShake'
  | 'swing'
  | 'tada'
  | 'wobble'
  | 'jello'
  | 'bounceIn'
  | 'bounceInDown'
  | 'bounceInLeft'
  | 'bounceInRight'
  | 'bounceInUp'
  | 'bounceOut'
  | 'bounceOutDown'
  | 'bounceOutLeft'
  | 'bounceOutRight'
  | 'bounceOutUp'
  | 'fadeIn'
  | 'fadeInDown'
  | 'fadeInDownBig'
  | 'fadeInLeft'
  | 'fadeInLeftBig'
  | 'fadeInRight'
  | 'fadeInRightBig'
  | 'fadeInUp'
  | 'fadeInUpBig'
  | 'fadeOut'
  | 'fadeOutDown'
  | 'fadeOutDownBig'
  | 'fadeOutLeft'
  | 'fadeOutLeftBig'
  | 'fadeOutRight'
  | 'fadeOutRightBig'
  | 'fadeOutUp'
  | 'fadeOutUpBig'
  | 'flipInX'
  | 'flipInY'
  | 'flipOutX'
  | 'flipOutY'
  | 'lightSpeedIn'
  | 'lightSpeedOut'
  | 'rotateIn'
  | 'rotateInDownLeft'
  | 'rotateInDownRight'
  | 'rotateInUpLeft'
  | 'rotateInUpRight'
  | 'rotateOut'
  | 'rotateOutDownLeft'
  | 'rotateOutDownRight'
  | 'rotateOutUpLeft'
  | 'rotateOutUpRight'
  | 'hinge'
  | 'jackInTheBox'
  | 'rollIn'
  | 'rollOut'
  | 'zoomIn'
  | 'zoomInDown'
  | 'zoomInLeft'
  | 'zoomInRight'
  | 'zoomInUp'
  | 'zoomOut'
  | 'zoomOutDown'
  | 'zoomOutLeft'
  | 'zoomOutRight'
  | 'zoomOutUp'
  | 'slideInDown'
  | 'slideInLeft'
  | 'slideInRight'
  | 'slideInUp'
  | 'slideOutDown'
  | 'slideOutLeft'
  | 'slideOutRight'
  | 'slideOutUp'
  | 'heartBeat'
  | 'scaleInFromTop'
  | 'scaleInFromBottom'
  | 'scaleInFromLeft'
  | 'scaleInFromRight'
  | 'scaleOutToTop'
  | 'scaleOutToBottom'
  | 'scaleOutToLeft'
  | 'scaleOutToRight'

export const Wrapper = styled(Box)<Props>`
	animation-name: ${props => props.animation};
	animation-duration: ${props => props.time + 'ms'} !important;
	animation-delay: ${props => props.delay + 'ms'} !important;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
	will-change: opacity, transform;

	/* Check if the animation starts with f for fade and add opacity: 0*/
	${props => (props.animation[0] === 'f' ? 'opacity: 0;' : null)}

	/* Check if the animation is a scaling animation and add inital transform property*/
  	${props => {
      if (props.animation.match('scaleInFromTop')) {
        return 'transform: scale(1,0);'
      }
      if (props.animation.match('scaleInFromBottom')) {
        return 'transform: scale(1,0);'
      }
      if (props.animation.match('scaleInFromLeft')) {
        return 'transform: scale(0,1);'
      }
      if (props.animation.match('scaleInFromRight')) {
        return 'transform: scale(0,1);'
      }
      return null
    }}

	@keyframes scaleInFromTop {
		0% {
			transform-origin: top center;
			transform: scale(1, 0);
		}
		100% {
			transform-origin: top center;
			transform: scale(1, 1);
		}
	}

	@keyframes scaleInFromBottom {
		0% {
			transform-origin: bottom center;
			transform: scale(1, 0);
		}
		100% {
			transform-origin: bottom center;
			transform: scale(1, 1);
		}
	}

	@keyframes scaleInFromLeft {
		0% {
			transform-origin: left center;
			transform: scale(0, 1);
		}
		100% {
			transform-origin: left center;
			transform: scale(1, 1);
		}
	}

	@keyframes scaleInFromRight {
		0% {
			transform-origin: right center;
			transform: scale(0, 1);
		}
		100% {
			transform-origin: right center;
			transform: scale(1, 1);
		}
	}

	@keyframes scaleOutToTop {
		0% {
			transform-origin: top center;
			transform: scale(1, 1);
		}
		100% {
			transform-origin: top center;
			transform: scale(1, 0);
		}
	}

	@keyframes scaleOutToBottom {
		0% {
			transform-origin: bottom center;
			transform: scale(1, 1);
		}
		100% {
			transform-origin: bottom center;
			transform: scale(1, 0);
		}
	}

	@keyframes scaleOutToLeft {
		0% {
			transform-origin: left center;
			transform: scale(1, 1);
		}
		100% {
			transform-origin: left center;
			transform: scale(0, 1);
		}
	}

	@keyframes scaleOutToRight {
		0% {
			transform-origin: right center;
			transform: scale(1, 1);
		}
		100% {
			transform-origin: right center;
			transform: scale(0, 1);
		}
	}
`

type Overflow = 'visible' | 'hidden'

export type ElementAnimationProps = {
  time: number
  delay: number
  animation: ANIMATE_CSS | string
  children?: any
  overflow?: Overflow
  offset?: number
  useIO: boolean
  sx?: any
  style?: any
  replay?: boolean
}

export const ElementAnimation = (props: Props) => {
  const { time, delay, children, animation, overflow, offset, useIO, sx, style, replay } = props

  const [play, setPlay] = React.useState(false)
  // Ref for the element that we want to detect whether on screen
  const ref = React.useRef()
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.
  const onScreen = useOnScreen(ref, `${offset || 0 * -1}px`)

  if (onScreen && play === false) {
    setPlay(true)
  }

  React.useEffect(() => {}, [])

  if (replay && useIO) {
    return (
      <div ref={ref} style={{ height: '100%', ...style }}>
        {onScreen && (
          <Wrapper sx={sx} style={{ overflow }} {...props} time={time} delay={delay} animation={animation || 'fadeIn'} className="animated">
            {children}
          </Wrapper>
        )}
      </div>
    )
  }

  if (useIO) {
    return (
      <div ref={ref} style={{ height: '100%', ...style }}>
        {play && (
          <Wrapper sx={sx} style={{ overflow }} {...props} time={time} delay={delay} animation={animation || 'fadeIn'} className="animated">
            {children}
          </Wrapper>
        )}
      </div>
    )
  }

  return (
    <Wrapper style={{ overflow }} {...props} time={time} delay={delay} animation={animation || 'fadeIn'} className="animated">
      {children}
    </Wrapper>
  )
}

// Hook
// Based on: https://github.com/TejasQ/react-hook-intersection-observer
function useOnScreen(ref, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = React.useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.unobserve(ref.current)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}

import React from 'react';
import styled from '@emotion/styled';
import { prism } from '../styles';
// Import Slices
import BodyText from './BodyText';
import CodeBlock from './CodeBlock';
import Image from './Image';
import Quote from './Quote';
import HeroIntroWithInputFields from './HeroIntroWithInputFields';
import BigPreviewCarousel from './BigPreviewCarousel';
import FeatureWithImages from './FeatureWithImages';
import VersionSection from './VersionSection';
import VersionCompare from './VersionCompare';
import LabelsSection from './LabelsSection';
import PresentationSection from './PresentationSection';
import ShareSection from './ShareSection';
import DownloadFileSection from './DownloadFileSection';
import IptcSection from './IptcSection';
import TwoColumnFeature from './TwoColumnFeature';
import EmailSubscribe from './EmailSubscribe';

const Content = styled.div`
	${prism};
	p,
	li {
		letter-spacing: -0.003em;
		--baseline-multiplier: 0.179;
		--x-height-multiplier: 0.35;
		font-size: 21px;
		line-height: 1.58;
		code {
			padding: 0.2rem 0.5rem;
			margin: 0.5rem 0;
		}
	}
	blockquote {
		margin-left: 0;
		padding-left: 1.45rem;
		border-left: 2px solid ${props => props.theme.colors.primary};
		p {
			font-size: 19px;
			font-style: italic;
		}
	}
`;

const SliceZone = ({ allSlices }: { allSlices: any[] }) => {
  const slice = allSlices.map(s => {
    switch (s.slice_type) {
    // Prismic API types of the general slices
    case 'text':
      return <BodyText key={s.id} input={s} />;
    case 'code_block':
      return <CodeBlock key={s.id} input={s} />;
    case 'image':
      return <Image key={s.id} input={s} />;
    case 'quote':
      return <Quote key={s.id} input={s} />;
      // Prismic API types of the custom slices
    case 'hero_text_with_input_fields':
      return <HeroIntroWithInputFields key={s.id} input={s} />;
    case 'big_preview_carousel':
      return <BigPreviewCarousel key={s.id} input={s} />;
    case 'feature_with_2_images':
      return <FeatureWithImages key={s.id} input={s} />;
    case 'version_section':
      return <VersionSection key={s.id} input={s} />;
    case 'version_compare':
      return <VersionCompare key={s.id} input={s} />;
    case 'labels_section':
      return <LabelsSection key={s.id} input={s} />;
    case 'presentation_section':
      return <PresentationSection key={s.id} input={s} />;
    case 'share_section':
      return <ShareSection key={s.id} input={s} />;
    case 'download_file_section':
      return <DownloadFileSection key={s.id} input={s} />;
    case 'iptc_section':
      return <IptcSection key={s.id} input={s} />;
    case '2_column_feature':
      return <TwoColumnFeature key={s.id} input={s} />;
    case 'email_subscribe':
      return <EmailSubscribe key={s.id} input={s} />;
    default:
      return null;
    }
  });
  return <>{slice}</>;
};

export default SliceZone;
